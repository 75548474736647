<ng-template [ngIf]="article">
  <div class="mb-6">
    <a routerLink="{{article.routerLink}}">
      <img src="{{article.image?.largeUrl}}" alt="{{article.image?.alt}}" class="w-full h-60 object-cover sm:h-80 sm:col-span-2 lg:col-span-full" loading="lazy" />
    </a>
    <h2 class="font-bold text-2xl mb-2 mt-2">
      <a routerLink="{{article.routerLink}}">
        {{article.title}}
      </a>
    </h2>
    <div class="mb-2 text-sm" [innerHTML]="article.perex"></div>
    <div class="grid grid-cols-2 gap-2 text-xs">
      <div class="flex">
        <div class="border-r border-black pr-2" [innerHTML]="article.publishedDateFormatted"></div>
        <div class="px-2" [innerHTML]="article.genre"></div>
      </div>
    </div>
  </div>
</ng-template>
