import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {AdPositionName, AdPositions} from "./ad-position-map";
import {sklikPositionId} from "../ad-provider/sklik/sklik.component";
import {adsensePositionId} from "../ad-provider/adsense/adsense.component";
import {sspPositionId} from "../ad-provider/ssp/ssp.component";
import {isPlatformBrowser} from "@angular/common";
import {AdDisplayType} from "../ad-provider/ad-provider";

@Component({
  selector: 'app-ad-position',
  templateUrl: './ad-position.component.html',
  styleUrls: ['./ad-position.component.css']
})
export class AdPositionComponent implements OnInit {
  @Input() positionName: AdPositionName | undefined;
  adPositions: AdPositions = new AdPositions();
  sklikPositionId: sklikPositionId | undefined;
  adsensePositionId: adsensePositionId | undefined;
  admasterPositionId: string | undefined;
  sspPositionId: sspPositionId | undefined;
  positionMinHeightClass: { [klass: string]: any; } | null | undefined;

  private readonly isBrowser: boolean;
  displayType: AdDisplayType;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.displayType = this.isBrowser ? (window.innerWidth > 767 ? AdDisplayType.desktop : AdDisplayType.mobile) : AdDisplayType.desktop;
  }

  ngOnInit(): void {
    if (this.positionName && typeof this.adPositions.map[this.positionName] !== "undefined") {
      this.sklikPositionId = this.displayType == AdDisplayType.mobile ? this.adPositions.map[this.positionName].sklikPositionMobile : this.adPositions.map[this.positionName].sklikPositionDesktop;
      this.adsensePositionId = this.displayType == AdDisplayType.mobile ? this.adPositions.map[this.positionName].adsensePositionMobile : this.adPositions.map[this.positionName].adsensePositionDesktop;
      this.sspPositionId = this.displayType == AdDisplayType.mobile ? this.adPositions.map[this.positionName].sspPositionMobile : this.adPositions.map[this.positionName].sspPositionDesktop;
      this.positionMinHeightClass = this.adPositions.map[this.positionName].minHeight ? { 'min-height': this.adPositions.map[this.positionName].minHeight + 'px' } : null;
    }
  }
}
