<ng-template [ngIf]="article">
  <div class="">
    <a routerLink="{{article.routerLink}}">
      <img src="{{article.image?.squareUrl}}" class="block sm:hidden w-full h-60 object-cover sm:h-52 sm:col-span-2 lg:col-span-full" loading="lazy" />
      <img src="{{article.image?.thumbnailUrl}}" class="hidden sm:block w-full h-60 object-cover sm:h-52 sm:col-span-2 lg:col-span-full" loading="lazy" />
    </a>
    <h3 class="font-bold mb-2 mt-2 md:text-sm">
      <a routerLink="{{article.routerLink}}">{{article.title}}</a>
    </h3>
    <div class="flex text-xs">
      <div class="border-r border-black pr-2" [innerHTML]="article.publishedDateFormatted"></div>
      <div class="px-2" [innerHTML]="article.genre"></div>
    </div>
  </div>
</ng-template>
