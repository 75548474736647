import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {AdDisplayType, AdProviderInterface} from "../ad-provider";
import {AdInterface, AdPositionName, AdPositions} from "../../ad-position/ad-position-map";
import {AdSeznamPopupComponent} from "../../ad-seznam-popup/ad-seznam-popup.component";
import {sspPositionId} from "../ssp/ssp.component";

export enum sklikPositionId {
  smr = '64410', // 300x300
  smrMobil = '274205', // 480x480
  smrMobilClanek = '282737', // 480x480
  smrMobilClanek2 = '282740', // 111x111
  smrMobilText = '359960', // 480x300
  skyscraper = '65235', // 300x600
  mobilePopup = '279290',
  wallpaper = '336160', // 480x300
  txtSklik = '64412', // 560x315
  footerboard = '64411', // 970x310
  leaderboard = '71217',
}

@Component({
  selector: 'app-sklik',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sklik.component.html',
  styleUrls: ['./sklik.component.css']
})
export class SklikComponent implements AdProviderInterface {
  @Input() positionId: string | undefined;
  sssp: any;
  displayType: AdDisplayType;
  adPositions: AdPositions = new AdPositions();
  ads: { [id: string]: AdInterface } = {
    '274205': {
      id: sklikPositionId.smrMobil,
      width: 480,
      height: 480,
      isMobile: true,
      isDesktop: true,
    },
    '282737': {
      id: sklikPositionId.smrMobilClanek,
      width: 480,
      height: 480,
      isMobile: true,
      isDesktop: true,
    },
    '65235': {
      id: sklikPositionId.skyscraper,
      width: 300,
      height: 600,
      isMobile: true,
      isDesktop: true,
    },
    '279290': {
      id: sklikPositionId.mobilePopup,
      width: 480,
      height: 480,
      isMobile: true,
      isDesktop: false,
    },
    '336160': {
      id: sklikPositionId.wallpaper,
      width: 480,
      height: 300,
      isMobile: true,
      isDesktop: true,
    },
    '64412': {
      id: sklikPositionId.txtSklik,
      width: 480,
      height: 480,
      isMobile: true,
      isDesktop: true,
    },
    '64411': {
      id: sklikPositionId.footerboard,
      width: 970,
      height: 310,
      isMobile: true,
      isDesktop: true,
    },
    '71217': {
      id: sklikPositionId.leaderboard,
      width: 480,
      height: 480,
      isMobile: true,
      isDesktop: true,
    },
    '64410': {
      id: sklikPositionId.smr,
      width: 480,
      height: 480,
      isMobile: false,
      isDesktop: true,
    },
    '282740': {
      id: sklikPositionId.smrMobilClanek2,
      width: 480,
      height: 480,
      isMobile: true,
      isDesktop: false,
    },
    '359960': {
      id: sklikPositionId.smrMobilText,
      width: 480,
      height: 300,
      isMobile: true,
      isDesktop: true,
    },
  }
  private readonly isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: string | boolean,
  ) {
    this.isBrowser = typeof platformId === 'string' ? isPlatformBrowser(platformId) : platformId;
    this.sssp = this.isBrowser ? (window as any).sssp : undefined;
    this.displayType = this.isBrowser ? (window.innerWidth > 767 ? AdDisplayType.desktop : AdDisplayType.mobile) : AdDisplayType.desktop;
  }

  serveAds(positions: Array<AdPositionName>): Array<AdPositionName> {
    if (!this.isBrowser) {
      return [];
    }

    let servedPositions: Array<AdPositionName> = [];
    const ads: Array<any> = [];
    positions.forEach((positionName: AdPositionName) => {
      const sklikPosition = this.displayType == AdDisplayType.mobile ? this.adPositions.map[positionName].sklikPositionMobile : this.adPositions.map[positionName].sklikPositionDesktop;
      if (positionName == AdPositionName.leaderboard) {
        const branding = this.serveBranding();
        if (branding) {
          ads.push(branding);
        }
        servedPositions.push(positionName);
        console.log('Serving SSP position ' + positionName + '(' + sklikPosition + ')');
      }
      else if (sklikPosition) {
        const ad = this.ads[sklikPosition];
        const adEl = document.getElementById("sklik-zone-" + ad.id);
        if (ad && adEl) {
          adEl.innerHTML = '';
          ads.push({zoneId: ad.id, id: "sklik-zone-" + ad.id, width: ad.width, height: ad.height});
          servedPositions.push(positionName);
          console.log('Serving Sklik position ' + positionName + '(' + sklikPosition + ')');
        }
      }
    });

    try {
      this.sssp?.getAds(ads);
    }
    catch (error) {
      console.error(error);
    }

    this.servePopup();

    return servedPositions;
  }

  serveBranding(): any | null {
    if (!this.isBrowser) {
      return null;
    }

    let brandedZone = null;
    if (window.innerWidth >= 1366) {
      const adEl = document.getElementById("branding");
      if (adEl) {
        adEl.innerHTML = '';
      }
      // vytvoří element pro branding hned za tagem <body>
      //document.body.insertAdjacentHTML('afterbegin', '<div id="sklik-zone-71217-branding"></div>');
      brandedZone = {
        "zoneId": 71217,
        "width": 2000,
        "id": "branding",
        "elements": [
          { id: "branding", width: 2000, height: 1400 }
        ]
      };
    }
    return brandedZone;
  }

  servePopup() {
    const configuration = [
      {
        type: "mobile",
        zoneId: 263413,
        breakPoint: 767,
        fromFeed: false
      },
      {
        type: "desktop",
        zoneId: 254083,
        breakPoint: 1000,
        onScrollElementId: "showPopUp",
        fromFeed: false
      }
    ];

    (window as any).getSznAdPopUp(configuration);
    console.log('Serving PopUp');
  }

}
