import { Injectable } from '@angular/core';
import {
  ArticleResult,
  ImageValue,
  MultipleFieldImage,
  MultipleFieldTaxonomyTerm,
  TaxonomyTermResult,
  TaxonomyTermValue
} from "./resultTypes";
import {ArticleInterface, Image, ImageInterface, SectionInterface, TagInterface} from "./entities";
import {Drupal7Service} from "./drupal7.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class Drupal7mappingService {

  constructor(
    private drupal7: Drupal7Service
  ) { }

  mapArticle(articleResult: ArticleResult) {
    let termId = 0;
    if (articleResult.field_rubrika.und && articleResult.field_rubrika.und[0].tid) {
      termId = articleResult.field_rubrika.und[0].tid;
    }

    let isPr = false;
    if (articleResult.field_pr.und && articleResult.field_pr.und[0].value) {
      isPr = !!+articleResult.field_pr.und[0].value;
    }

    let article: ArticleInterface = {
      id: articleResult.nid,
      title: articleResult.title,
      created: articleResult.created,
      changed: articleResult.changed,
      authorName: articleResult.name,
      publishedDateFormatted: this.drupal7.formatTimestamp(articleResult.created),
      changedDateFormatted: this.drupal7.formatTimestamp(articleResult.changed),
      perex: '',
      body: '',
      path: '',
      routerLink: '/clanek/' + this.drupal7.generateArticlePath(articleResult.nid, articleResult.title, termId),
      section: this.drupal7.getSectionName(termId),
      isPr: isPr,
      tags: [],
    }
    article.fullUrl = environment.baseUrl + article.routerLink;
    article.urlEncoded = encodeURIComponent(article.fullUrl);

    if (article.isPr) {
      article.genre = 'komerční&nbsp;sdělení';
    }
    else if (Object.keys(articleResult.field_genre).length) {
      // @ts-ignore
      article.genre = this.drupal7.getGenreName(articleResult.field_genre.und[0].tid);
    }
    if (Object.keys(articleResult.field_perex).length) {
      // @ts-ignore
      article.perex = articleResult.field_perex.und[0].value;
    }
    if (Object.keys(articleResult.field_text).length) {
      // @ts-ignore
      article.body = articleResult.field_text.und[0].value;
    }
    if (Object.keys(articleResult.field_primary_image).length) {
      article.image = {
        id: articleResult.field_primary_image.und[0].fid,
        title: articleResult.field_primary_image.und[0].title,
        filemime: articleResult.field_primary_image.und[0].filemime,
        url: this.drupal7.getFileUrlFromUri(articleResult.field_primary_image.und[0].uri),
        largeUrl: this.drupal7.getImageStyleUrlFromUri(articleResult.field_primary_image.und[0].uri, 'image_detail'),
        squareUrl: this.drupal7.getImageStyleUrlFromUri(articleResult.field_primary_image.und[0].uri, 'medium_crop'),
        thumbnailUrl: this.drupal7.getImageStyleUrlFromUri(articleResult.field_primary_image.und[0].uri, 'thumbnail_crop'),
        width: articleResult.field_primary_image.und[0].width,
        height: articleResult.field_primary_image.und[0].height,
        path: '',
        isGallery: false,
      }
      if (Object.keys(articleResult.field_primary_image.und[0].field_file_image_title_text).length) {
        // @ts-ignore
        article.image.alt = articleResult.field_primary_image.und[0].field_file_image_title_text.und[0].value;
      }
      if (Object.keys(articleResult.field_primary_image.und[0].field_file_image_copyright).length) {
        // @ts-ignore
        article.image.copyright = articleResult.field_primary_image.und[0].field_file_image_copyright.und[0].value;
      }
    }
    if (Object.keys(articleResult.field_primary_image).length && Object.keys(articleResult.field_fotogalerie).length) {
      this.drupal7.getGalleryNode(articleResult.field_fotogalerie.und[0].target_id).subscribe(galleryResult => {
        // @ts-ignore
        article.image.isGallery = true;
        // @ts-ignore
        article.image.galleryImagesCount = galleryResult.field_images.und.length;
        // @ts-ignore
        article.image.galleryRouterLink = '/fotogalerie/' + this.drupal7.generateNodePath(galleryResult.nid, galleryResult.title);
        // @ts-ignore
        article.image.galleryRouterFragment = 'back=/node/' + article.id;
      });
    }
    else if (Object.keys(articleResult.field_fotogalerie).length) {
      this.drupal7.getGalleryNode(articleResult.field_fotogalerie.und[0].target_id).subscribe(galleryResult => {
        article.image = {
          id: galleryResult.field_images.und[0].fid,
          title: galleryResult.field_images.und[0].title,
          filemime: galleryResult.field_images.und[0].filemime,
          url: this.drupal7.getFileUrlFromUri(galleryResult.field_images.und[0].uri),
          largeUrl: this.drupal7.getImageStyleUrlFromUri(galleryResult.field_images.und[0].uri, 'image_detail'),
          squareUrl: this.drupal7.getImageStyleUrlFromUri(galleryResult.field_images.und[0].uri, 'medium_crop'),
          thumbnailUrl: this.drupal7.getImageStyleUrlFromUri(galleryResult.field_images.und[0].uri, 'thumbnail_crop'),
          width: galleryResult.field_images.und[0].width,
          height: galleryResult.field_images.und[0].height,
          path: '',
          isGallery: true,
          galleryImagesCount: galleryResult.field_images.und.length,
          galleryRouterLink: '/fotogalerie/' + this.drupal7.generateNodePath(galleryResult.nid, galleryResult.title),
          galleryRouterFragment: 'back=/node/' + article.id,
        }
        if (Object.keys(galleryResult.field_images.und[0].field_file_image_title_text).length) {
          // @ts-ignore
          article.image.alt = galleryResult.field_images.und[0].field_file_image_title_text.und[0].value;
        }
        if (Object.keys(galleryResult.field_images.und[0].field_file_image_copyright).length) {
          article.image.copyright = galleryResult.field_images.und[0].field_file_image_copyright.und[0].value;
        }
      });
    }
    return article;
  }

  mapArticles(data: Array<ArticleResult>) {
    const articles: Array<ArticleInterface> = [];
    Object.entries(data).forEach(([key, articleResult]) => {
      articles?.push(this.mapArticle(articleResult));
    });

    return articles;
  }

  mapImage(data: ImageValue) {
    const image: ImageInterface = {
      id: data.fid,
      title: data.title,
      filemime: data.filemime,
      url: this.drupal7.getFileUrlFromUri(data.uri),
      largeUrl: this.drupal7.getImageStyleUrlFromUri(data.uri, 'image_detail'),
      squareUrl: this.drupal7.getImageStyleUrlFromUri(data.uri, 'medium_crop'),
      thumbnailUrl: this.drupal7.getImageStyleUrlFromUri(data.uri, 'thumbnail_crop'),
      width: data.width,
      height: data.height,
      path: '',
    };
    if (Object.keys(data.field_file_image_copyright).length) {
      image.copyright = data.field_file_image_copyright.und[0].value;
    }

    return image;
  }

  mapImages(data: Array<ImageValue>) {
    const images: Array<ImageInterface> = [];
    Object.entries(data).forEach(([key, imageResult]) => {
      images?.push(this.mapImage(imageResult));
    });

    return images;
  }

  mapSection(data: any) {
    const section: SectionInterface = {
      id: data.tid,
      title: data.name,
      description: '',
      path: '',
      routerLink: this.drupal7.generateSectionPath(data.tid, data.name),
    };
    section.fullUrl = environment.baseUrl + section.routerLink;

    return section;
  }

  mapSections(data: Array<TaxonomyTermResult>) {
    const sections: Array<SectionInterface> = [];
    Object.entries(data).forEach(([key, sectionResult]) => {
      sections?.push(this.mapSection(sectionResult));
    });

    return sections;
  }

  mapTag(data: TaxonomyTermResult) {
    const tag: TagInterface = {
      id: data.tid,
      title: data.name,
      description: data.description,
      path: '',
      routerLink: this.drupal7.generateTagPath(data.tid, data.name),
    }
    tag.fullUrl = environment.baseUrl + tag.routerLink;
    return tag;
  }
}
