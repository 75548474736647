<div class="max-w-[1060px] mx-auto">
  <div class="block md:flex p-6 md:space-x-12">
    <div class="w-full md:w-2/3 md:flex-1" itemscope itemtype="https://schema.org/Article">
      <ng-template [ngIf]="article" [ngIfElse]="loader">
        <h1 itemprop="headline">{{article.title}}</h1>
        <div class="hidden" itemprop="publisher" itemscope itemtype="https://schema.org/Organization">
          <meta itemprop="name" content="ČtiDoma.cz" />
        </div>
        <meta class="hidden" itemprop="dateModified" content="{{ article.changedDateFormatted }}" />
        <link class="hidden" itemprop="mainEntityOfPage" [href]="fullUrl" />
        <link class="hidden" itemprop="discussionUrl" routerLink="{{article.routerLink + '/diskuse'}}" />
        <ng-template [ngIf]="article.image">
        <div class="mb-6">
          <div class="relative">
            <img src="{{article.image.largeUrl}}" alt="{{article.image.alt}}" itemprop="image" loading="lazy" />
            <div class="absolute bottom-6 left-6 bg-white rounded-full px-2 py-1 text-xs">
              <la-icon icon="info-circle"></la-icon>
              <div class="inline-block ml-1">{{article.image.copyright}}</div>
            </div>
            <ng-template [ngIf]="article.image.isGallery">
              <a routerLink="{{article.image.galleryRouterLink}}" fragment="{{article.image.galleryRouterFragment}}" class="absolute bottom-6 right-6 bg-orange-800 rounded-full px-2 py-1 text-xs text-white hover:bg-white hover:text-orange-800">
                <la-icon icon="images"></la-icon>
                <div class="inline-block ml-1">Zobrazit fotogalerii ({{article.image.galleryImagesCount}})</div>
              </a>
            </ng-template>
          </div>
          <div class="bg-white px-4 py-2 text-xs">{{article.image.title}}</div>
        </div>
        </ng-template>

        <div class="mb-2 text-xs flex items-stretch">
          <div class="w-5/6">
            <div class="flex mb-1">
              <div class="border-r border-black pr-2" itemprop="datePublished" [innerHTML]="article.publishedDateFormatted"></div>
              <div class="px-2" [innerHTML]="article.genre"></div>
            </div>
            <div class="flex" itemprop="author" itemscope itemtype="http://schema.org/Person">
              Autor: &nbsp;<strong itemprop="name">{{article.authorName}}</strong>
            </div>
          </div>
          <div class="w-1/6 grid grid-cols-2 space-x-2 items-stretch justify-end text-center">
            <a href="http://facebook.com/sharer.php?u={{article.urlEncoded}}" class="inline-block w-10 text-black hover:text-orange-800" target="_blank"><la-icon icon="lab facebook-f" size="2x"></la-icon></a>
            <a href="http://twitter.com/intent/tweet?url={{article.urlEncoded}}" class="inline-block w-10 text-black hover:text-orange-800" target="_blank"><la-icon icon="lab twitter" size="2x"></la-icon></a>
          </div>
        </div>
        <div class="font-bold mb-6" itemprop="description" [innerHTML]="article.perex"></div>

        <div class="w-full">
          <app-ad-position [positionName]="AdPositionName.articlePerex"></app-ad-position>
        </div>

        <div [innerHTML]="article.body | safeHtml"></div>
        <div id="showPopUp"></div>
        <div class="grid grid-cols-2 mb-6">
          <div class="text-left">
            <div class="fb-like" [attr.data-href]="article.path" data-layout="button" data-action="like" data-share="true"></div>
          </div>
          <div class="text-right">
            <ng-template [ngIf]="article.fullUrl">
              <app-seznam-pocitadlolibise [url]="article.fullUrl"></app-seznam-pocitadlolibise>
            </ng-template>
          </div>
        </div>

        <div class="w-full">
          <app-ad-position [positionName]="AdPositionName.articleBottom"></app-ad-position>
        </div>

        <ng-template [ngIf]="article.tags" class="mb-6">
          <a *ngFor="let tag of article.tags" routerLink="{{ tag.routerLink }}" class="bg-orange-800 text-white text-xs no-underline px-2 py-1 rounded-full mr-2 mb-2 inline-block hover:bg-white hover:text-orange-800">
            #{{ tag.title }}
          </a>
        </ng-template>

        <div class="text-center my-4">
          <a class="btn-large" routerLink="{{article.routerLink + '/diskuse'}}"><la-icon icon="comments" class="pr-2"></la-icon> Vstoupit do diskuse</a>
        </div>

        <div data-szn-recass-box='{"zone_id": 21143}'></div>
      </ng-template>

      <ng-template #loader>
        <div class="loader"><la-icon class="animate-spin" icon="spinner" size="2x"></la-icon></div>
        <!-- <div class="cite-left cite-right" -->
      </ng-template>
    </div>
    <div class="w-full md:w-1/3 md:max-w-[300px] flex items-stretch">
      <app-sidebar class="relative w-full"></app-sidebar>
    </div>
  </div>
</div>
