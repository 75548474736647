<div class="max-w-[1060px] mx-auto">
  <div class="block md:flex p-6 pb-0 border-b border-black md:space-x-12">
    <div class="w-full md:w-2/3 md:max-w-fit">
      <ng-template [ngIf]="topArticles" [ngIfElse]="loader">
        <app-article-large [article]="topArticles[0]"></app-article-large>
        <div class="lg:hidden">
          <app-ad-position [positionName]="AdPositionName.smrMobilClanek"></app-ad-position>
        </div>
        <div class="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
          <div>
            <app-article-box [article]="topArticles[1]"></app-article-box>
          </div>
          <div>
            <app-article-box [article]="topArticles[2]"></app-article-box>
            <div class="lg:hidden">
              <app-ad-position [positionName]="AdPositionName.smrMobilClanek2"></app-ad-position>
            </div>
          </div>
          <div>
            <app-article-box [article]="topArticles[3]"></app-article-box>
          </div>
          <div>
          <app-article-box [article]="topArticles[4]"></app-article-box>
          </div>
          <div>
            <app-article-box [article]="topArticles[5]"></app-article-box>
          </div>
          <div>
            <app-article-box [article]="topArticles[6]"></app-article-box>
          </div>
        </div>
      </ng-template>
      <ng-template #loader>
        <div class="loader"><la-icon class="animate-spin" icon="spinner" size="2x"></la-icon></div>
      </ng-template>
    </div>
    <div class="w-full md:w-1/3 md:max-w-[300px]">
      <app-ad-position
        [positionName]="AdPositionName.square"
      ></app-ad-position>
      <app-most-read></app-most-read>
    </div>
  </div>
</div>

<div class="max-w-[1060px] mx-auto">
  <div class="block md:flex p-6 pb-2 border-b border-black md:space-x-12">
    <div class="w-full md:w-1/2">
      <ng-template [ngIf]="teaserArticles" [ngIfElse]="loader">
        <div *ngFor="let article of teaserArticles" class="mb-6">
          <app-article-medium [article]="article"></app-article-medium>
        </div>
      </ng-template>
    </div>
    <div class="w-full md:w-1/2 relative pb-6">
      <app-ad-position class="sticky top-6"
        [positionName]="AdPositionName.stickyRectangle"
      ></app-ad-position>
    </div>
  </div>
</div>

<div class="max-w-[1060px] mx-auto pb-4">
  <div class="grid sm:grid-cols-2 p-6 pb-2 gap-12">
    <ng-template [ngIf]="sections" [ngIfElse]="loader">
      <div *ngFor="let section of sections; index as i">
        <h2 class="text-2xl text-orange-800 mb-2">
          <a routerLink="{{section.routerLink}}" class="no-underline">{{section.title}}</a>
        </h2>
        <ng-template [ngIf]="sectionArticles[i] && sectionArticles[i][0]">
          <div class="mb-4">
            <div class="flex items-center space-x-6">
              <div class="w-1/3">
                <img src="{{sectionArticles[i][0].image?.thumbnailUrl}}" class="w-full h-140 object-cover sm:h-140 sm:col-span-2 lg:col-span-full" loading="lazy" />
              </div>
              <div class="w-2/3">
                <h2 class="font-bold mb-2 mt-2"><a routerLink="{{sectionArticles[i][0].routerLink}}">{{sectionArticles[i][0].title}}</a></h2>
                <div class="grid grid-cols-2 gap-2 text-xs">
                  <div class="flex">
                    <div class="border-r border-black pr-2" [innerHTML]="sectionArticles[i][0].publishedDateFormatted"></div>
                    <div class="px-2" [innerHTML]="sectionArticles[i][0].genre"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="sectionArticles[i] && sectionArticles[i][1]">
          <div class="mb-4">
            <a routerLink="{{sectionArticles[i][1].routerLink}}">{{sectionArticles[i][1].title}}</a>
          </div>
        </ng-template>
        <ng-template [ngIf]="sectionArticles[i] && sectionArticles[i][2]" class="mb-6">
          <div>
            <a routerLink="{{sectionArticles[i][2].routerLink}}">{{sectionArticles[i][2].title}}</a>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>
<div id="showPopUp"></div>
