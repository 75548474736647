import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {AdDisplayType, AdProviderInterface} from "../ad-provider";
import {AdInterface, AdPositionName, AdPositions} from "../../ad-position/ad-position-map";
import {SklikComponent} from "../sklik/sklik.component";
import {environment} from "../../../../environments/environment";

declare global {
  interface Window { adsbygoogle: any; }
}

export enum adsensePositionId {
  square = '5577500943', // 300x300
  squareSticky = '2943498355', // 300x300
  skyscraper = '5364025758', // 300x600
  mobilePopup = '5045504043', // 320x100
  rectangle = '1229339624', // 480x300
  wallpaper = '3352105935', // 480x300
  footerboard = '3584040223', // 970x310,
  // leaderboard = undefined,
}

@Component({
  selector: 'app-adsense',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './adsense.component.html'
})
export class AdsenseComponent implements AdProviderInterface {
  @Input() positionId: string | undefined;
  displayType: AdDisplayType;
  adPositions: AdPositions = new AdPositions();
  ads: { [id: string]: AdInterface } = {
    '5577500943': {
      id: adsensePositionId.square,
      width: 300,
      height: 300,
      isMobile: true,
      isDesktop: true,
    },
    '2943498355': {
      id: adsensePositionId.squareSticky,
      width: 300,
      height: 300,
      isMobile: true,
      isDesktop: true,
    },
    '5364025758': {
      id: adsensePositionId.skyscraper,
      width: 300,
      height: 600,
      isMobile: true,
      isDesktop: true,
    },
    '3352105935': {
      id: adsensePositionId.wallpaper,
      width: 480,
      height: 480,
      isMobile: true,
      isDesktop: true,
    },
    '1229339624': {
      id: adsensePositionId.rectangle,
      width: 480,
      height: 480,
      isMobile: true,
      isDesktop: true,
    },
    '3584040223': {
      id: adsensePositionId.footerboard,
      width: 480,
      height: 480,
      isMobile: true,
      isDesktop: true,
    },
    '5045504043': {
      id: adsensePositionId.mobilePopup,
      width: 320,
      height: 100,
      isMobile: true,
      isDesktop: true,
    },
  }

  private readonly isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: string | boolean,
  ) {
    this.isBrowser = typeof platformId === 'string' ? isPlatformBrowser(platformId) : platformId;
    this.displayType = this.isBrowser ? (window.innerWidth > 767 ? AdDisplayType.desktop : AdDisplayType.mobile) : AdDisplayType.desktop;
  }

  serveAds(positions: Array<AdPositionName>): Array<AdPositionName> {
    if (!this.isBrowser) {
      return [];
    }

    let servedPositions: Array<AdPositionName> = [];
    let fallbackPositions: Array<AdPositionName> = [];
    positions.forEach((positionName: AdPositionName) => {
      const adsensePosition = this.displayType == AdDisplayType.mobile ? this.adPositions.map[positionName].adsensePositionMobile : this.adPositions.map[positionName].adsensePositionDesktop;
      const fallbackPosition = this.displayType == AdDisplayType.mobile ? this.adPositions.map[positionName].sklikPositionMobile : this.adPositions.map[positionName].sklikPositionDesktop;
      if (adsensePosition) {
        const ad = this.ads[adsensePosition];
        if (ad) {
          const containerEl = document.getElementById("adsense-zone-" + ad.id);
          if (containerEl) {
            containerEl.innerHTML = '';
            let adEl = document.createElement("ins");
            adEl.classList.add("adsbygoogle");
            adEl.dataset['adClient'] = "ca-pub-7167776733084441";
            adEl.dataset['adSlot'] = ad.id;
            adEl.style.display = "block";
            adEl.dataset['adFormat'] = "auto";
            adEl.dataset['fullWidthResponsive'] = "true";
            if (!environment.production) {
              adEl.dataset['adtest'] = "on";
            }

            containerEl.appendChild(adEl);
            servedPositions.push(positionName);

            try {
              (window.adsbygoogle = window.adsbygoogle || []).push({});
            }
            catch (error) {
              console.error(error);
            }
          }
          else {
            console.log('zone not found: ' + ad.id);
          }
        }
        else {
          console.log('ad mapping not found: ' + adsensePosition);
        }
      }
      else if (fallbackPosition) {
        fallbackPositions.push(positionName);
      }
    });

    if (fallbackPositions) {
      let fallbackProvider = new SklikComponent(this.isBrowser);
      servedPositions.push(...fallbackProvider.serveAds(fallbackPositions));
    }

    return servedPositions;
  }
}
