<ng-template [ngIf]="article">
  <div class="mb-6">
    <div class="flex items-center space-x-6">
      <div class="w-1/5">
        <a routerLink="{{article.routerLink}}">
          <img src="{{article.image?.thumbnailUrl}}" alt="{{article.image?.alt}}" class="w-full h-140 object-cover sm:h-140 sm:col-span-2 lg:col-span-full" alt="{{article.image?.alt}}" loading="lazy" />
        </a>
      </div>
      <div class="w-4/5">
        <h2 class="font-bold mb-2 mt-2">
          <a routerLink="{{article.routerLink}}">
            {{article.title}}
          </a>
        </h2>
        <div class="grid grid-cols-2 gap-2 text-xs">
          <div class="flex">
            <div class="border-r border-black pr-2" [innerHTML]="article.publishedDateFormatted"></div>
            <div class="px-2" [innerHTML]="article.genre"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
